import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

// import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component<{
  location: any
  title: string
  children: any
}> {
  render() {
    return (
      <StaticQuery
        query={query}
        render={({
          site: {
            siteMetadata: { author, social },
          },
        }) => {
          const { location, title, children } = this.props
          // @ts-ignore
          const rootPath = `${__PATH_PREFIX__}/`
          let header

          if (location.pathname === rootPath) {
            header = (
              <h1 className="text-3xl md:text-5xl font-black font-sans mb-10 mt-0">
                <Link className="shadow-none" to={`/`}>
                  {title}
                </Link>
              </h1>
            )
          } else {
            header = (
              <h3 className="text-xl md:text-2xl font-sans font-black mt-0">
                <Link className="shadow-none" to={`/`}>
                  {title}
                </Link>
              </h3>
            )
          }
          return (
            <div className="h-screen flex flex-col font-body">
              <div className="flex-1 max-w-2xl mx-auto px-5 py-10 text-center md:text-left">
                <header>{header}</header>
                <main>{children}</main>
                <footer className="mt-16">
                  © {new Date().getFullYear()}.{" "}
                  <a href={`https://twitter.com/${social.twitter}`}>{author}</a>
                  . Made with <small>♥</small> by{" "}
                  <a
                    className="text-blue-600"
                    href="https://clerklabs.com"
                    target="_blank"
                  >
                    Clerk Labs
                  </a>{" "}
                  (my alter ego)
                </footer>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default Layout

const query = graphql`
  query {
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`
